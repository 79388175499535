/* eslint-disable react/require-default-props */
import React, {
  ChangeEvent,
  FocusEventHandler,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
} from 'react'
import {
  Autocomplete,
  FormControl,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Paper,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { SelectOptionType } from './index'

const PaperComponent = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
}))

export type SelectProps = {
  clearable?: boolean
  dataQa?: string | undefined
  disabled?: boolean
  defaultValue?: string | undefined
  helperText?: React.JSX.Element | string | undefined
  hideTextInputValue?: boolean
  label?: string | undefined
  error?: string
  onChange: (
    event: ChangeEvent<HTMLSelectElement>,
    option: SelectOptionType
  ) => void
  options: SelectOptionType[]
  required?: boolean | undefined
  value: string | ''
  name?: string | ''
  onBlur?: (event: FocusEventHandler<HTMLInputElement>) => void
}

const Select: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    clearable = false,
    dataQa = undefined,
    disabled = false,
    defaultValue = '',
    helperText = undefined,
    hideTextInputValue = false,
    label = undefined,
    options = [],
    required = false,
    value = '',
    error = '',
    name = '',
    onBlur,
    onChange,
    ...rest
  },
  ref
): React.JSX.Element => {
  const optionsWithEmpty: SelectOptionType[] = options.some(
    opt => opt.value === ''
  )
    ? options
    : [...options, { value: '', label: '', key: 'empty-Value' }]

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, option: SelectOptionType) => {
      event.target.name = name
      onChange(event, option)
    },
    [name]
  )

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...rest}
        PaperComponent={PaperComponent}
        data-qa={dataQa}
        disabled={disabled}
        disableClearable={!clearable}
        filterOptions={(availableOptions: SelectOptionType[], search) =>
          availableOptions.filter(option => {
            if (option.value) {
              const lowerSearch = search.inputValue.toLowerCase()
              const toSearch = (option.text || option.label || '').toLowerCase()
              return lowerSearch && toSearch
                ? toSearch.includes(lowerSearch)
                : true
            }

            return false
          })
        }
        getOptionLabel={(val: string): string => {
          if (!val) {
            return ''
          }

          const optionFromVal = options.find(opt => opt.value === val)

          if (optionFromVal) {
            return optionFromVal.text || optionFromVal.label
          }

          return ''
        }}
        groupBy={(option: SelectOptionType) => option.group}
        isOptionEqualToValue={(
          option: SelectOptionType | string,
          currentValue
        ) =>
          option.value === currentValue ||
          option === value ||
          (currentValue &&
            currentValue.value &&
            option.value === currentValue.value)
        }
        loadingText='Loading...'
        onChange={handleChange}
        openOnFocus
        options={optionsWithEmpty}
        renderInput={params => {
          const { inputProps } = params

          if (hideTextInputValue) {
            inputProps.value = ''
          }

          return (
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error || helperText}
              inputProps={inputProps}
              label={label}
              name={name}
              onBlur={onBlur}
              required={required}
            />
          )
        }}
        ref={ref}
        renderOption={(props, option: SelectOptionType) => {
          const optFromOptions = options.find(opt => opt.value === option.value)

          if (!optFromOptions) {
            return null
          }

          return (
            <ListItemButton
              {...props}
              component={ListItem}
              disabled={optFromOptions.disabled}
              key={option.key}
            >
              <ListItemText
                primary={option.label}
                secondary={optFromOptions && optFromOptions.description}
              />
            </ListItemButton>
          )
        }}
        value={
          options && options.length > 0
            ? value || defaultValue || ''
            : defaultValue || ''
        }
      />
    </FormControl>
  )
}

export default forwardRef(Select)
