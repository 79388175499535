/* eslint no-useless-escape: 0 */

import {
  action,
  observable,
  IObservableArray,
  makeObservable,
  override,
  reaction,
  toJS,
} from 'mobx'

import ContentData from '../Model/ContentData'
import Link from './Link'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { useLog } from '../lib/log'

import { ContentProps, LinkProps } from '../types'

const { API_HOST } = __GLOBAL__

const log = useLog()
export default class Content extends ModelBase {
  constructor(rootStore: RootStore, content: ContentProps) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      createdAt: observable,
      updatedAt: observable,
      org_id: observable,
      author: observable,
      title: observable,
      summary: observable,
      type: observable,
      content: observable,
      hidePreviewImage: observable,
      investments: observable,
      readBy: observable,
      tags: observable,
      groups: observable,
      users: observable,
      guests: observable,
      starred: observable,
      is_global: observable,
      recordingUrl: observable,
      createdForId: observable,
      isInProgress: observable,
      links: observable,
      disable_comments: observable,
      file: observable,
      validationErrors: observable,
      saveError: observable,
      saveSuccess: observable,
      isSaving: observable,
      isLoadingMetadata: observable,
      availableTags: observable,
      data: override,
      getMetadata: action,
      save: action,
      toggleArchived: action,
      category: override,
      showActions: override,
      permalink: override,
      videoExists: action,
      validationSchema: override,
    })

    this.isPublicShareAllowed = true
    this.org_id = rootStore.orgStore.currentOrg._id

    if (content) {
      this.isInProgress = content.isInProgress || false
      this.createdAt = content.createdAt || Date.now()
      this.author = content.author
      Object.keys(content).forEach((key: keyof ContentProps) => {
        this[key] =
          key === 'content'
            ? new ContentData(content[key])
            : content[key] || this[key]
      })
      const links = observable.array(
        content.links
          ? content.links.map(link => new Link(link, undefined))
          : []
      )
      this.links = links
      this.permissionTags = observable.array(content?.permissionTags || [])
      this.clientIds = observable.array(content?.clientIds || [])
    }

    reaction(() => this.content.url, this.getMetadata)
  }

  contentTypeMap = {
    document: {
      name: 'File',
      icon: 'file',
    },
    link: {
      name: 'Link',
      icon: 'linkify',
    },
    media: {
      name: 'Video',
      icon: 'play circle outline',
    },
  }

  createdForId: string | undefined = undefined

  modelCollection = 'content'

  _id: string | undefined = undefined

  createdAt: number | undefined = undefined

  updatedAt: number | undefined = undefined

  org_id = ''

  author: string | undefined = undefined

  title = ''

  summary = ''

  type = 'text'

  content = new ContentData()

  hidePreviewImage = false

  investments = false

  readBy: string[] = []

  tags: string[] = []

  groups: string[] = []

  users: string[] = []

  guests: string[] = []

  starred = false

  is_global = false

  links: IObservableArray<Link> = observable.array([])

  disable_comments = false

  file = false

  validationErrors = {}

  saveError: string | undefined = undefined

  saveSuccess = false

  isSaving = false

  isLoadingMetadata = false

  availableTags: string[] = []

  isInProgress: boolean | undefined = true

  get permalink(): string {
    return `/news-updates/${this.slug}`
  }

  videoExists = async () => {
    await this.getRecordingUrl()

    return Boolean(this.recordingUrl)
  }

  get category(): string {
    return 'content'
  }

  get showActions() {
    if (!this.rootStore.orgStore || !this.rootStore.orgStore.currentOrg) {
      return false
    }

    const currentOrg = this.rootStore?.orgStore.currentOrg

    return (
      ((this.is_global ? currentOrg.isHost : true) && currentOrg.isOrgAdmin) ||
      (currentOrg.isOrgContributor &&
        this.rootStore.userStore._id === this.author)
    )
  }

  get data(): ContentProps {
    return {
      _id: toJS(this._id),
      archivedAt: toJS(this.archivedAt),
      author: toJS(this.author),
      org_id: toJS(this.org_id),
      title: toJS(this.title),
      summary: toJS(this.summary),
      type: toJS(this.type),
      hidePreviewImage: toJS(this.hidePreviewImage),
      investments: toJS(this.investments),
      tags: toJS(this.tags),
      groups: toJS(this.groups),
      users: toJS(this.users),
      disable_comments: toJS(this.disable_comments),
      isPublic: toJS(this.isPublic),
      is_global: toJS(this.is_global),
      isInProgress: toJS(this.isInProgress),
      permissionTags: toJS(this.permissionTags),
      createdForId: toJS(this.createdForId),
      content: this.content.data,
      clientIds: toJS(this.clientIds),
      updatedAt: toJS(this.updatedAt),
      links: this.links
        .filter(link => link.hasEnoughData)
        .map(link => link.data),
    }
  }

  getMetadata = async () => {
    let { url } = this.content

    if (!url.startsWith('http')) {
      url = `http://${url}`
    }

    if (
      url.indexOf(API_HOST) >= 0 ||
      !url.match(
        /^(?:https?:\/\/)?[^\s\/\.]+(?:\.[a-z0-9-]{2,})+(?:\/\S*)?$/i
      ) ||
      this.type === 'document'
    ) {
      return false // No lookup for document uploads
    }

    // We've already fetched the metadata
    if (this._id && !this.isInProgress) {
      return false
    }

    try {
      this.isLoadingMetadata = true
      const { data } = await this.client.content.getMetadata({
        org_id: this.org_id,
        url: this.content.url,
      })

      this.content.image = this.content.image || data.image || ''
      this.title = this.title || data.title || ''
      this.summary =
        this.summary && this.summary !== '<p><br></p>'
          ? this.summary
          : data.description
          ? `<p>${data.description}</p>`
          : this.summary || '<p><br></p>'
    } catch (e) {
      /* swallow */
    }

    this.isLoadingMetadata = false

    return true
  }

  recordingUrl = ''

  getRecordingUrl = async () => {
    if (this?.content?.id && this.type === 'video' && !this.recordingUrl) {
      const { data } = await this.client.meetings.getRecordingUrl(
        this.rootStore.orgStore.currentOrg._id,
        this.content.id
      )

      this.recordingUrl = data
    }

    return this.recordingUrl
  }

  save = async (sendNotification = false) => {
    this.validationErrors = []
    this.saveError = undefined
    this.saveSuccess = false
    this.isSaving = true

    try {
      const { data } = await this.client.content.saveContent(
        this.data,
        this._id,
        sendNotification
      )
      this.saveSuccess = true
      this._id = data._id
      this.createdAt = data.createdAt
      this.updatedAt = data.updatedAt

      const links = observable.array(
        data.links ? data.links.map((link: LinkProps) => new Link(link)) : []
      )
      this.links = links

      const newPiece = new Content(this.rootStore, data)

      if (
        !this.rootStore.contentStore.content.some(
          piece => piece._id === this._id
        )
      ) {
        this.rootStore.contentStore.content.push(newPiece)
      } else {
        this.rootStore.contentStore.content.replace(
          this.rootStore.contentStore.content.map(piece =>
            piece._id === this._id ? newPiece : piece
          )
        )
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        this.validationErrors = e.response.data.reduce((acc, i) => {
          acc[i.key] = i.message
          return acc
        }, {})
        this.saveError = 'Please ensure all fields are filled out correctly'
        log.code('cnt201', { error: e.response })
      } else {
        if (e.message === 'ERR:Upload') {
          this.saveError = 'Could not upload file, please contact support'
          log.code('cnt301', {
            error: e.response,
          })
          return
        }
        this.saveError =
          'There was an error saving your content, please contact support'
        log.code('cnt302', { error: e.response })
      }
    } finally {
      this.isSaving = false
      setTimeout(() => {
        this.saveError = undefined
        this.saveSuccess = false
      }, 20000)
    }
  }

  toggleArchived = async () => {
    const { data } = await this.client.content.toggleArchived(
      this.org_id,
      this._id
    )

    this.archivedAt = data.archivedAt

    return data
  }

  get displayName(): string {
    return 'News & Updates'
  }

  get permissionsName(): string {
    return 'Recipient'
  }
}
