// LOG CODES
// ---------
// Allow for centralized maintenance of log calls
// Naming: <3-letter-code><number>

const categories = {
  content: {
    event: {
      cnt001: { action: 'save', label: null },
      cnt002: { action: 'view', label: null },
    },
    info: {},
    warn: {
      cnt201: { action: 'save', label: 'validation' },
    },
    error: {
      cnt301: { action: 'upload', label: 'document' },
      cnt302: { action: 'save', label: null },
    },
  },

  event: {
    event: {
      evt001: { action: 'save', label: null },
      evt002: { action: 'rsvp', label: null },
    },
    info: {},
    warn: {
      evt201: { action: 'save', label: 'validation' },
    },
    error: {
      evt301: { action: 'save', label: null },
    },
  },

  organization: {
    event: {
      org001: { action: 'save', label: null },
      org002: { action: 'remove', label: 'member' },
      org003: { action: 'remove', label: 'group' },
      org004: { action: 'save', label: 'group' },
      org005: { action: 'register', label: 'member' },
      org006: { action: 'invite', label: null },
      org007: { action: 'store', label: 'group' },
      org008: { action: 'save', label: 'organization list' },
      org009: { action: 'remove', label: 'organization list' },
    },
    info: {},
    warn: {
      org201: { action: 'save', label: 'validation' },
      org202: { action: 'register', label: 'member' },
    },
    error: {
      org301: { action: 'save', label: null },
      org302: { action: 'save', label: 'group' },
      org303: { action: 'register', label: 'member' },
      org304: { action: 'get', label: 'investment looks' },
      org305: { action: 'invite', label: null },
      org306: { action: 'join', label: 'user' },
      org307: { action: 'save', label: 'organization list' },
    },
  },

  survey: {
    event: {
      sur001: { action: 'submit', label: 'answers' },
      sur002: { action: 'save', label: null },
    },
    info: {},
    warn: {},
    error: {
      sur301: { action: 'submit', label: 'answers' },
      sur302: { action: 'save', label: null },
    },
  },

  user: {
    event: {
      usr001: { action: 'login', label: null },
      usr002: { action: 'account recovery', label: null },
      usr003: { action: 'password reset', label: null },
      usr004: { action: 'signup', label: null },
      usr005: { action: 'save', label: null },
    },
    info: {},
    warn: {
      usr201: { action: 'login', label: null },
      usr202: { action: 'account recovery', label: 'invalid' },
      usr203: { action: 'signup', label: 'invalid' },
      usr204: { action: 'save', label: 'validation' },
    },
    error: {
      usr301: { action: 'account recovery', label: null },
      usr302: { action: 'password reset', label: null },
      usr303: { action: 'signup', label: null },
      usr304: { action: 'save', label: null },
    },
  },

  platform: {
    event: {
      ptf001: { action: 'cta-click', label: 'dashboard' },
    },
    info: {},
    warn: {},
    error: {
      ptf301: { action: 'error', label: 'critical' },
      pft302: { action: 'socket', label: 'connection' },
    },
  },

  poll: {
    event: {
      pol001: { action: 'save', label: null },
      pol002: { action: 'answer', label: null },
    },
    info: {},
    warn: {
      pol201: { action: 'save', label: 'validation' },
    },
    error: {
      pol301: { action: 'save', label: null },
      pol302: { action: 'answer', label: null },
    },
  },

  documents: {
    event: {
      doc001: { action: 'save', label: null },
      doc002: { action: 'download', label: null },
      doc003: { action: 'create', label: 'folder' },
      doc004: { action: 'upload', label: 'file' },
      doc005: { action: 'delete', label: 'folder' },
      doc006: { action: 'delete', label: 'file' },
      doc007: { action: 'preview', label: null },
      doc008: { action: 'replaceFile', label: 'file' },
    },
    info: {},
    warn: {
      doc201: { action: 'save', label: 'validation' },
      doc202: { action: 'preview', label: null },
    },
    error: {
      doc301: { action: 'save', label: null },
      doc302: { action: 'upload', label: 'GetUploadUrl' },
      doc303: { action: 'upload', label: 'PutFile' },
      doc304: { action: 'download' },
      doc305: { action: 'create', label: 'folder' },
      doc306: { action: 'replace', label: null },
    },
  },

  discussions: {
    event: {
      dsc001: { action: 'save', label: null },
      dsc002: { action: 'create', label: 'message' },
      dsc003: { action: 'delete', label: 'message' },
      dsc004: { action: 'save', label: 'message' },
      dsc005: { action: 'delete', label: null },
    },
    info: {},
    warn: {
      dsc201: { action: 'save', label: 'validation' },
      dsc202: { action: 'save', label: 'message' },
    },
    error: {
      dsc301: { action: 'save', label: null },
      dsc302: { action: 'delete', label: null },
      dsc303: { action: 'delete', label: 'message' },
      dsc304: { action: 'create', label: 'message' },
      dsc305: { action: 'save', label: 'message' },
    },
  },

  meetings: {
    event: {
      mtg001: { action: 'join', label: null },
      mtg002: { action: 'joinPublic', label: null },
    },
    info: {},
    warn: {},
    error: {
      mtg301: { action: 'join', label: null },
    },
  },

  trackReadBy: {
    event: {
      trb001: { action: 'track', label: 'read' },
    },
    info: {},
    warn: {},
    error: {
      trb301: { action: 'track', label: 'read' },
    },
  },

  share: {
    event: {
      shr001: { action: 'share', label: 'share' },
    },
    info: {},
    warn: {
      shr201: { action: 'share', label: 'share' },
    },
  },

  auditLogs: {
    event: {
      aud001: { action: 'create', label: 'viewLog' },
    },
    info: {},
    warn: {
      aud301: { action: 'create', label: 'viewLog' },
    },
    error: {},
  },

  contacts: {
    event: {
      cts001: { action: 'save', label: null },
      cts002: { action: 'get', label: null },
    },
    info: {},
    warn: {
      cts201: { action: 'save', label: 'validation' },
    },
    error: {
      cts301: { action: 'save', label: null },
      cts302: { action: 'get', label: null },
    },
  },

  campaigns: {
    event: {
      cmp001: { action: 'save', label: null },
      cmp002: { action: 'get', label: null },
      cmp003: { action: 'delete', label: null },
      cmp004: { action: 'view', label: 'public' },
      cmp005: { action: 'track', label: 'public' },
    },
    info: {},
    warn: {
      cmp201: { action: 'save', label: 'validation' },
    },
    error: {
      cmp301: { action: 'save', label: null },
      cmp302: { action: 'delete', label: null },
      cmp303: { action: 'view', label: 'public' },
      cmp304: { action: 'track', label: 'public' },
    },
  },

  alerts: {
    event: {
      alt001: { action: 'get', label: null },
      alt002: { action: 'refresh', label: 'triggered' },
      alt003: { action: 'remove', label: null },
    },
    info: {},
    warn: {
      alt201: { action: 'refresh', label: null },
      alt202: { action: 'get', label: 'unauthorized' },
    },
    error: {
      alt301: { action: 'get', label: null },
      alt302: { action: 'remove', label: null },
    },
  },

  organizationForms: {
    event: {
      ogf001: { action: 'save', label: null },
      ogf002: { action: 'download', label: 'pdf' },
    },
    info: {},
    warn: {},
    error: {
      ogf301: { action: 'save', label: null },
      ogf302: { action: 'pdf', label: 'upload' },
      ogf303: { action: 'pdf', label: 'download' },
    },
  },

  relationshipManagement: {
    event: {},
    info: {},
    warn: {
      rl201: { action: 'defaultUserRoles', label: 'update' },
      rl202: { action: 'defaultUserRoles', label: 'save' },
    },
    error: {},
  },

  todos: {
    event: {
      tds001: { action: 'save', label: null },
      tds002: { action: 'remove', label: null },
      tds003: { action: 'markComplete', label: null },
      tds004: { action: 'markUserComplete', label: null },
      tds005: { action: 'markIncomplete', label: null },
    },
    info: {},
    warn: {},
    error: {
      tds301: { action: 'save', label: null },
      tds302: { action: 'remove', label: null },
      tds303: { action: 'markComplete', label: null },
      tds304: { action: 'markUserComplete', label: null },
      tds305: { action: 'markIncomplete', label: null },
    },
  },
}

// BUILD MASTER CODE LIB
const codes = Object.keys(categories).reduce((acc, category: string) => {
  Object.keys(categories[category]).forEach(severity => {
    const catObject = categories[category]
    Object.keys(catObject[severity]).forEach(code => {
      if (acc[code]) throw new Error(`LOG CODE ALREADY EXIST ${code}`)
      acc[code] = Object.assign(catObject[severity][code], {
        category,
        severity,
      })
    })
  })
  return acc
}, {})

export default codes
