import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from 'mobx'
import moment from 'moment'
import * as yup from 'yup'

import Client from '../lib/client'
import { generateSlug } from '../lib/slug'
import { useLog } from '../lib/log'

import RootStore from '../stores/RootStore'

import DiscussionMessage from './DiscussionMessage'
import Link from './Link'

import { AnyObject } from '../types'

const log = useLog()
export default class ModelBase {
  client = new Client()

  modelCollection: string | undefined = undefined

  org_id: string | undefined = undefined

  _id: string | undefined = undefined

  user_id: string | undefined = undefined

  userId: string | undefined = undefined

  readBy: string[] | undefined = undefined

  messages: IObservableArray<DiscussionMessage> | undefined = undefined

  key: string | undefined = undefined

  tags: string[]

  title: string | undefined = undefined

  topic: string | undefined = undefined

  question: string | undefined = undefined

  name: string | undefined = undefined

  groups:
    | string[]
    | {
        id?: string | undefined
        isGlobalGroup?: boolean | undefined
        isHostGroup?: boolean | undefined
        members?: string[]
        name?: string | undefined
      }[]
    | undefined = undefined

  users:
    | string[]
    | {
        _id: string
        role: string
        orgRole?: string
        deletedAt?: number
        expiresAt?: number
        joinedAt?: number
      }[]
    | undefined = undefined

  author: string | undefined = undefined

  creator: string | undefined = undefined

  createdForId: string | undefined = undefined

  is_global: boolean | undefined = undefined

  clientIds: IObservableArray<string> = observable.array([])

  permissionTags: IObservableArray<string> = observable.array([])

  isInProgress: boolean | undefined = undefined

  rootStore: RootStore = undefined

  archivedAt: number | undefined = undefined

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(rootStore: RootStore, _data?: unknown, _org?: unknown) {
    this.rootStore = rootStore
    makeObservable(this, {
      slug: computed,
      category: computed,
      permalink: computed,
      listSubtitle: computed,
      listTitle: computed,
      addLink: action,
      createAuditLog: action,
      share: action,
      displayName: computed,
      archivedAt: observable,
      clientIds: observable,
      isPublicShareAllowed: observable,
      isPublic: observable,
      permissionTags: observable,
      shareUrl: observable,
      shareUrlExpiresAt: observable,
      inheritedGroups: computed,
      inheritedUsers: computed,
      generatePublicUrl: action,
      icon: computed,
      iconColor: computed,
      permissionsName: computed,
      showActions: computed,
      posted: computed,
      getBlankLink: action,
      formData: computed,
      data: computed,
      validationSchema: computed,
      canAddTags: computed,
    })
  }

  get icon(): string {
    return this.category
  }

  get iconColor(): string {
    return ''
  }

  get inheritedGroups(): string[] {
    return []
  }

  get inheritedUsers(): string[] {
    return []
  }

  get isArchived(): boolean {
    return Boolean(this.archivedAt)
  }

  get slug(): string {
    if (this.org_id && this._id) {
      return generateSlug(this.org_id, this._id)
    }
    return ''
  }

  get permalink(): string {
    if (this.org_id && this._id) {
      return `/${this.modelCollection}/${generateSlug(this.org_id, this._id)}`
    }
    return ''
  }

  get category(): string {
    return this.modelCollection
  }

  get validationSchema(): yup.Schema<object> {
    return yup.object().shape({})
  }

  get canAddTags(): boolean {
    return (
      this?.rootStore?.orgStore?.currentOrg?.isOrgAdmin &&
      this?.rootStore?.tagStore?.tags.some(tag => tag.type === 'simple')
    )
  }

  links: IObservableArray<Link> = observable.array([])

  addLink = () => {
    if (this.org_id && this.links) {
      this.links.push(new Link({ org_id: this.org_id }, this.org_id))
    }
  }

  getBlankLink = () => {
    return new Link({ org_id: this.org_id }, this.org_id)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  save(...args: unknown[]) {
    throw new Error('Method not implemented.')
  }

  markAsRead = async (userId: string) => {
    if (
      this.modelCollection === 'documents' ||
      localStorage.getItem('impersonatingId')
    ) {
      return true
    }

    if (
      this._id &&
      Array.isArray(this?.readBy) &&
      !this.readBy.includes(userId)
    )
      this.readBy.push(userId)
    try {
      await this.client.readby.track(
        this.org_id,
        this.modelCollection,
        this._id,
        {
          userId,
        }
      )
      log.code('trb001', {
        type: this.modelCollection,
        id: this._id,
      })
      // For discussions mark all messages read locally to reflect read status in UI
      if (this.modelCollection === 'discussions') {
        this.messages.replace(
          this.messages.map((m: DiscussionMessage) => {
            if (!m.readBy.includes(userId)) {
              m.readBy.push(userId)
            }

            return m
          })
        )
      }
      return true
    } catch (e) {
      log.code('trb301', {
        type: this.modelCollection,
        id: this._id,
        error: e,
      })
      return false
    }
  }

  createAuditLog = async (userId: string, at: number, data: unknown) => {
    if (this.modelCollection === 'documents' && (this.userId || this.user_id))
      return
    try {
      const uid = this.modelCollection === 'documents' ? this.key : this._id
      await this.client.auditLogs.createLog(
        this.is_global
          ? this?.rootStore?.orgStore?.currentOrg?._id || this.org_id
          : this.org_id,
        this.modelCollection,
        uid,
        {
          userId,
          at,
          data,
        }
      )
      log.code('aud001', {
        type: this.modelCollection,
        id: this._id,
      })
    } catch (e) {
      log.code('aud301', {
        type: this.modelCollection,
        id: this._id,
        error: e,
      })
    }
  }

  getNotifyId = (): string => (this._id ? this._id.toString() : '')

  getDownloadString = (): string => ''

  getPathString = (): string => ''

  getStarUID = (): string => this.getNotifyId()

  share = async (message: string) => {
    const type = this.modelCollection || 'documents'
    const typeid = this.getNotifyId()

    try {
      const response = await this.client.notify.share(this.org_id.toString(), {
        type,
        typeid,
        message,
      })
      log.code('shr001', {
        type,
        id: typeid,
        message: 'success',
      })

      return response
    } catch (e) {
      log.code('shr201', {
        type,
        id: typeid,
        error: e,
      })
    }

    return false
  }

  get displayName(): string {
    return 'Item'
  }

  isPublicShareAllowed = false

  isPublic = false

  shareUrl = ''

  shareUrlExpiresAt: number | undefined = undefined

  fetchingPublicUrl = false

  generatePublicUrl = async (creating: boolean) => {
    if (this.isPublic && !this.fetchingPublicUrl) {
      this.fetchingPublicUrl = true

      try {
        const payload = {
          type: this.modelCollection || 'documents',
          _id: this._id ? this._id.toString() : '',
          download: this.getDownloadString(),
          path: this.getPathString(),
          expiresAt: this.shareUrlExpiresAt || 0,
          creating: creating || false,
        }

        const { data } = this.org_id
          ? await this.client.shorties.gernerateShortenedUrl(
              this.org_id.toString(),
              payload
            )
          : { data: false }

        if (!data) {
          throw new Error('Invalid request')
        }

        this.shareUrl = data.shareUrl
        this.shareUrlExpiresAt = data.expiresAt
        this.fetchingPublicUrl = false

        return data
      } catch (e) {
        const message = 'Unable to generate a public url'
        this.fetchingPublicUrl = false

        log.warn({ message, error: e })

        throw new Error(message)
      }
    }

    return false
  }

  get permissionsName(): string {
    return 'Permission'
  }

  get listTitle(): string {
    return this.title || this.topic || this.question || this.name || ''
  }

  get listUpdatedAt(): string {
    return moment(this.updatedAt).format('lll')
  }

  get listSubtitle(): string {
    return ''
  }

  get showActions() {
    if (!this.rootStore.orgStore || !this.rootStore.orgStore.currentOrg) {
      return false
    }
    const currentOrg = this.rootStore?.orgStore.currentOrg

    return (
      currentOrg.isOrgAdmin ||
      (currentOrg.isOrgContributor &&
        this.rootStore.userStore._id === this.author)
    )
  }

  get data(): AnyObject {
    return {}
  }

  get formData() {
    return this.data
  }

  get posted() {
    const useTimestampForDisplay =
      this.modelCollection === 'discussions' &&
      this.messages &&
      this.messages.length
        ? this.messages.reduce(
            (acc, m) => (m.timestamp > acc ? m.timestamp : acc),
            0
          )
        : this.updatedAt ||
          this.updated ||
          this.modified ||
          this.createdAt ||
          this.created ||
          0

    return useTimestampForDisplay
      ? useTimestampForDisplay > Date.now() - 120000
        ? 'now'
        : moment(useTimestampForDisplay).fromNow()
      : ''
  }
}
